/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import device from 'current-device'

import 'lazysizes'
import 'lazysizes/plugins/attrchange/ls.attrchange'

import disableMediaSession from './src/helpers/disable-media-session'

// Import global styles
import './src/styles/fonts.css'
import './src/styles/site.css'

// Store the device info into the window scope
window.device = device

// Disable media session so media key won't get hijacked by background videos
disableMediaSession()

window.onload = function(){
    setTimeout(function(){
        if (!window.performance || !('getElementsByClassName' in document)) {
            console.log('No Performance API')
            return
        }

        var timing = window.performance.timing
        var loadTime = timing.loadEventEnd - timing.responseEnd

        console.log('Load Time', loadTime)

        }, 0)
  }
