// Disables key actions for media session
// so this browser session doesn't override another media
export default function disableMediaSession () {
    const supportsMediaSession = ('mediaSession' in navigator)

    // If this browser doesn't support media session then stop
    if (!supportsMediaSession) return

    navigator.mediaSession.setActionHandler('play', function() { /* Nothing. */ })
    navigator.mediaSession.setActionHandler('pause', function() { /* Nothing. */ })
    navigator.mediaSession.setActionHandler('seekbackward', function() { /* Nothing. */ })
    navigator.mediaSession.setActionHandler('seekforward', function() { /* Nothing. */ })
    navigator.mediaSession.setActionHandler('previoustrack', function() { /* Nothing. */ })
    navigator.mediaSession.setActionHandler('nexttrack', function() { /* Nothing. */ })
}
